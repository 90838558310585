<template>
    <el-form 
        ref="candidateResumeWorkForm" 
        class="candidate-resume-work-form col-15" 
        id="candidate-resume-work-form" 
        autocomplete="off" 
        v-loading="loading"
        :model="formData"
        :rules="rules"
    >
        <input type="hidden" name="Id" v-bind:value="formData.id">

        <div class="form-group col-14 flex-center" style="margin-bottom: 15px;">
            <!-- <checkbox :checked="formData.isLatestWorkExperience" @on-click='AddWork' :disable='isCheckboxLocked' identity="isLatestWorkExperience" class="label-select label-select-margin"></checkbox> -->
            <el-checkbox 
                v-model="formData.isLatestWorkExperience"
                :disabled="isCheckboxLocked"
                size="medium"
                class="last-experience-checkbox"
            ></el-checkbox>
            <label class="label-select-text">设为最近工作经历</label>
        </div>

        <div class="form-group col-7">
            <el-form-item label="公司" prop="company" required>
                <customer-select-or-write
                    v-if="formData.company || formData.isAdd"
                    :customerId.sync="formData.customerId"
                    :customerName.sync="formData.company"
                    @finish-callback="finishCallback"
                    />
            </el-form-item>
        </div>

        <div class="form-group col-7" v-if="formData.isLatestWorkExperience && formData.customerId && showDepart(formData.tempCustomerId, formData.customerId)">
            <el-form-item label="部门" prop="customerDepartmentId">
                <customer-department-select
                    ref="departmentSelect"
                    v-if="formData.isLatestWorkExperience && formData.customerId"
                    :canSrcoll="false"
                    :customerId="formData.customerId"
                    :departmentId="formData.customerDepartmentId"
                    :customerDepartmentName="formData.customerDepartmentName"
                    @update-department="updateDepartment"
                    placeholder='输入完整路径，多级部门用"，"分隔'
                ></customer-department-select> 
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="职位" prop="title" required>
                <el-input v-model="formData.title" placeholder="请输入" />
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="开始时间" prop="startDate">
                <el-date-picker
                    class="start-date-picker"
                    style="width: 100%"
                    v-model="formData.startDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="startDateOptions"
                >
                </el-date-picker>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="结束时间" prop="endDate">
                <el-date-picker
                    ref="endDatePicker"
                    class="end-date-picker"
                    style="width: 100%"
                    v-model="formData.endDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="endDateOptions"
                >
                </el-date-picker>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="地区" prop="locationId">
                <city-select ref="citySelect" @citySelected="handleCitySelected"></city-select>
            </el-form-item>
        </div>

        <div class="form-group col-14">
            <el-form-item label="工作内容" prop="description">
                <el-input type="textarea" :rows="3" v-model="formData.description" placeholder="请输入" />
            </el-form-item>
        </div>

        <div class="form-group col-14 footer-btns">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <div class="delete-btn" v-show="!isCheckboxLocked" @click="_delete">删除本条</div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import moment from 'moment';
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { isNaN } from '#/js/util/tools.js';
    import { min as getMin, max as getMax} from '#/js/util/validators.js';

    import CitySelect from '#/component/common/city-select.vue';
    import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
    import CustomerSelectOrWrite from '#/component/common/customer-select-or-write.vue';

    let defaultFormObject = {
        id: '',
        company: '',
        title: '',
        startDate: '',
        endDate: '',
        location: '',
        description: '',
        isLatestWorkExperience: '',
        customerDepartmentName: '',
        customerDepartmentId: '',
        customerId: '',
        tempCustomerId: '',
        currentCompany: ''
    }

    export default {
        components: {
            // Checkbox,
            CitySelect,
            CustomerDepartmentSelect,
            CustomerSelectOrWrite,
        },
        data () {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObject),
                rules: {
                    company: [
                        { required: true, message: '公司不能为空', trigger: ['blur', 'change'] },
                        { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                        { max: 128, message: '公司最长支持128个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ],
                    title: [
                        { required: true, message: '职位不能为空', trigger: ['blur', 'change'] },
                        { min: 2, message: '不能少于 2 个字符', trigger: ['blur', 'change'] },
                        { max: 128, message: '职位最长支持128个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ],
                    startDate: [
                        { required: true, message: '开始时间不能为空', trigger: ['blur', 'change'] },
                        { validator: this.validStartDate, trigger: ['blur', 'change'] }
                    ],
                    endDate: [
                        // { required: true, message: '结束时间不能为空', trigger: ['blur', 'change'] },
                        { validator: this.validEndDate, trigger: ['blur', 'change'] }
                    ],
                    description: [
                        { max: 8000, message: '工作内容最长支持8000个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDescription, trigger: ['blur'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ]
                },
                type: resumeItemType.workExperiences,
                hasSetCityData: false,
                isCheckboxLocked: false,
                loading:false,
                startDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    }
                },
                endDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            self.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                            self.$refs.endDatePicker.handleClose();
                        }
                    }]
                }
            }
        },
        props: {
            canEdit: {
                type: Boolean,
                default: true
            },
            candidateId:String,
            editUrl:String,
            addUrl:String,
        },
        computed: {
            showDepart() {
                return (tempCustomerId, customerId) => {
                    // 有tempId
                    if(!!tempCustomerId) {
                        return customerId == tempCustomerId || this.formData.currentCompany == this.formData.company;
                    } else {
                        return !!customerId;
                    }
                }
            }
        },
        mounted () {
            this.form = this.$refs.candidateResumeWorkForm;
            this.citySelect = this.$refs.citySelect;
            // this.initEvent();
        },
        methods: {
            updateDepartment(department = {}) {
                this.formData.customerDepartmentId = department.id;
                this.formData.customerDepartmentName = department.departmentName;
            },
            finishCallback () {
                this.formData.customerDepartmentId = null;
                this.formData.customerDepartmentName = '';

                this.$nextTick(() => {
                    this.$refs.departmentSelect && this.$refs.departmentSelect.reset();
                });
            },
            AddWork(vm){
                if (vm.$data.active) {
                    this.formData.isLatestWorkExperience=true;
                } else {
                    this.formData.isLatestWorkExperience=false;
                }
            },
            _cancel () {
                this._reset();
                this.$nextTick(() => {
                    this.$emit('resume-work-form-cancel');
                })
            },

            _delete () {
                this._showConfirmDialog();
            },

            _create() {
                this._reset();

                this.formData['isAdd'] = true;
                this.isCheckboxLocked=false;
            },

            _reset () {
                this.formData = Object.assign({}, defaultFormObject);
                this.citySelect.reset();
                this.formData.isLatestWorkExperience=false;
                this.formData['isAdd'] = true;
                this.isCheckboxLocked=false;

                this.$nextTick(() => {
                    this.$refs.candidateResumeWorkForm.clearValidate();
                })

            },

            _refresh () {
                this.$emit('candidate-detail-refresh')
            },

            validStartDate(rule, value, callback) {
                if (value == '') {
                    callback();
                }
                if (isNaN(new Date(value).getTime())) {
                    callback(new Error('开始时间日期格式不符'));
                } else {
                    callback();
                }

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;

                if (this.formData.endDate || endDateText == '至今') {
                    this.$refs['candidateResumeWorkForm'].validateField('endDate', valid => {});
                }
            },

            validEndDate(rule, value, callback) {
                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                
                if (value == '') {
                    if (endDateText == '至今') {
                        if (moment() - moment(this.formData.startDate) > 45*365*24*60*60*1000) {
                            callback(new Error("请输入真实的工作经历时间"));
                            return;
                        } else {
                            callback();
                            return;
                        }
                    } else {
                        callback(new Error('结束时间不能为空'));
                        return;
                    }
                }

                if(endDateText != '至今') {
                    if (isNaN(new Date(value).getTime())) {
                        callback(new Error('结束时间日期格式不符'));
                    } else if (new Date(value).getTime() < new Date(this.formData.startDate).getTime()) {
                        callback(new Error('结束时间应大于开始时间'));
                    } else if (this.formData.startDate && (new Date(value).getTime() - new Date(this.formData.startDate).getTime() > 45*365*24*60*60*1000)) {
                        callback(new Error("请输入真实的工作经历时间"));
                    } else {
                        callback();
                    }
                } else {
                    if (moment() - moment(this.formData.startDate) > 45*365*24*60*60*1000) {
                        callback(new Error("请输入真实的工作经历时间"));
                    } else {
                        callback();
                    }
                }
            },

            validDescription(rule, value, callback) {
                if (value == '') {
                    callback();
                    return;
                }
                if (value && value.length < 20) {
                    callback(new Error('不能少于 20 个字符'));
                    return;
                }
                callback();
            },

            validDangeroutStr(rule, value, callback) {
                let dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;
                if(dangerousStringReg.test(value)) {
                    callback(new Error('您输入的内容包含非法字符'));
                } else {
                    callback();
                }
            },

            _submitValidate () {
                let formData = this.formData;
                let isValid = true;

                formData = this.trimValue(formData);

                this.$refs.candidateResumeWorkForm.validate((validator) => {
                    if (validator) {
                        for (var key in formData) {
                            let value = formData[key];
        
                            switch (key) {
                                case 'location':
                                    if (!value) {
                                        formData.location = 0;
                                    }
                                    break;
                                case 'isLatestWorkExperience':
                                    if (!value) {
                                        formData.isLatestWorkExperience = false;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }

                        this.loading = true;
                        this._submitCandidateEdit(formData);
                    } else {
                        console.log('error submit')
                    }
                })
            },

            _submitCandidateEdit (formData) {
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                let workExperience = {
                    ...formData,
                    customerDepartmentId: formData.customerDepartmentId || 0
                };
                if(endDateText == '至今') {
                    workExperience.endDate = '9999-12-31';
                }
                _request({
                    url: postUrl,
                    method: 'POST',
                    data: {
                        id: this.candidateId,
                        type: this.type,
                        data: {
                            workExperience
                        }    
                    }
                }).then(res => {
                    this.$emit('cache-select-compnay', this.formData.customerId);
                    this._cancel();
                    this._refresh();
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.citySelect.reset();
                }).finally(() => {
                    this.loading = false;
                })
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue (obj) {
                let newOjb = {};
                Object.keys(obj).forEach(key => {
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData (data) {
                if (data.id && data.isLatestWorkExperience) {
                    this.isCheckboxLocked = true;
                } else {
                    this.isCheckboxLocked = false;
                }

                Object.assign(this.formData, data);
                this.citySelect.setDefaultCity(this.formData.location);


                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? new Date(): this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? new Date(): this.formData.startDate;


                if(this.formData.tempCustomerId) {
                    this.formData.customerId = this.formData.tempCustomerId;
                }
                if(this.formData.customerId == '0') {
                    this.formData.customerId = null;
                }
                this.$nextTick(() => {
                    if(this.$refs.departmentSelect && this.formData.customerDepartmentId) {
                        this.$refs.departmentSelect.value = this.formData.customerDepartmentId;
                        this.$refs.departmentSelect.editInit({
                            id: this.formData.customerDepartmentId
                        });
                    }
                })
            },

            initEvent () {
                let self = this;
                this.initAllDateRange();
            },

            handleCitySelected(province, city) {
                this.formData.location = city;
            }
        }

    }
</script>

<style lang="scss">
    @import './candidate-resume-work-form.scss';
    .department-tip{
        // display: block;
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin-top: 4px;
    }

    .candidate-resume-work-form {
        .form-group {
            margin-bottom: 0;
        }

        .footer-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pull-right {
                flex-shrink: 0;
            }
        }

        .el-form-item {
            display: flex;
            flex-direction: column;

            .el-form-item__label {
                display: inline-flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                line-height: 24px;

                &::before {
                    margin-left: 4px;
                }
            }

            .el-form-item__content {
                width: 100%;
                margin-left: 0!important;
            }
        }

        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
