<template>
    <el-form 
        ref="candidateResumeEducationForm" 
        v-loading="loading" 
        class="candidate-resume-education-form col-15" 
        id="candidate-resume-education-form" 
        autocomplete="off"
        :model="formData"
        :rules="rules"
    >
        <input type="hidden" name="Id" :value="formData.id">

        <div class="form-group col-7">
            <el-form-item label="学校" prop="school" required>
                <el-autocomplete 
                    class="select-search select-search-school" 
                    v-model="formData.school" 
                    :fetch-suggestions="querySchoolSearch" 
                    placeholder="请输入内容" 
                    @select="handleSchool" 
                    popper-class="edit-dialog-autocomplete"
                >
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.name }}</div>
                    </template>
                </el-autocomplete>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="学历" prop="degree" required>
                <el-select
                    v-model="formData.degree"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in degreeArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="开始时间" prop="startDate">
                <el-date-picker
                    class="start-date-picker"
                    style="width: 100%"
                    v-model="formData.startDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="startDateOptions"
                >
                </el-date-picker>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="结束时间" prop="endDate">
                <el-date-picker
                    ref="endDatePicker"
                    class="end-date-picker"
                    style="width: 100%"
                    v-model="formData.endDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="endDateOptions"
                >
                </el-date-picker>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="专业" prop="major">
                <el-autocomplete 
                    class="select-search" 
                    v-model="formData.major" 
                    :fetch-suggestions="queryMajorSearch" 
                    placeholder="请输入内容" 
                    @select="handleMajor" 
                    popper-class="edit-dialog-autocomplete"
                >
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.name }}</div>
                    </template>
                </el-autocomplete>
            </el-form-item>
        </div>

        <div class="form-group col-14 footer-btns">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <div class="delete-btn" @click="_delete">删除本条</div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import moment from 'moment';
    import { candidate as candidateConfig } from '#/js/config/api.json';
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { isNaN } from '#/js/util/tools.js';
    import { min as getMin, max as getMax} from '#/js/util/validators.js';
    import degreeData from '#/js/config/degreeData.json';

    let defaultFormObj = {
        "id": "",
        "school": "",
        "degree": "",
        "startDate": "",
        "endDate": "",
        "major": ""
    }

    export default {
        components: {
            // SelectSearch
        },
        data() {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObj),
                rules: {
                    school: [
                        { required: true, message: '学校不能为空', trigger: ['blur', 'change'] },
                        { min: 2, message: '不能少于 2 个字符', trigger: ['blur', 'change'] },
                        { max: 128, message: '学校最长支持128个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ],
                    degree: [
                        { required: true, message: '学历不能为空', trigger: ['blur', 'change'] },
                    ],
                    startDate: [
                        { required: true, message: '开始时间不能为空', trigger: ['blur', 'change'] },
                        { validator: this.validStartDate, trigger: ['blur', 'change'] }
                    ],
                    endDate: [
                        // { required: true, message: '结束时间不能为空', trigger: ['blur', 'change'] },
                        { validator: this.validEndDate, trigger: ['blur', 'change'] }
                    ],
                    major: [
                        { required: true, message: '专业不能为空', trigger: ['blur', 'change'] },
                        { min: 2, message: '不能少于 2 个字符', trigger: ['blur', 'change'] },
                        { max: 128, message: '专业最长支持128个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ],
                },
                universityUrl: candidateConfig.url_university_list,
                majorUrl: candidateConfig.url_major_list,
                type: resumeItemType.educations,
                majorList:[],
                universityList:[],
                degreeArr: degreeData.degreeArr,
                loading:false,
                startDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    }
                },
                endDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            self.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                            self.$refs.endDatePicker.handleClose();
                        }
                    }]
                }
            }
        },
        props:{
            candidateId: String,
            editUrl: String,
            addUrl: String,
        },
        mounted () {
            this.form = this.$refs.candidateResumeEducationForm;
            // this.schoolSelect = this.$refs.schoolSelectSearch;
            // this.majorSelect = this.$refs.majorSelectSearch;
            this.initEvent();
        },
        methods: {
            _cancel(){
                this.$emit('resume-education-form-cancel');
            },

            _delete(){
                this._showConfirmDialog();
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);

                this.$nextTick(() => {
                    this.$refs.candidateResumeEducationForm.clearValidate();
                })
            },

            _refresh(){
                this.$emit('candidate-detail-refresh')
            },

            validStartDate(rule, value, callback) {
                if (value == '') {
                    callback();
                }
                if (isNaN(new Date(value).getTime())) {
                    callback(new Error('开始时间日期格式不符'));
                } else {
                    callback();
                }

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;

                if (this.formData.endDate || endDateText == '至今') {
                    this.$refs['candidateResumeEducationForm'].validateField('endDate', valid => {});
                }
            },

            validEndDate(rule, value, callback) {
                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;

                if (value == '') {
                    if (endDateText == '至今') {
                        if (moment() - moment(this.formData.startDate) < 365*24*60*60*1000) {
                            callback(new Error("请输入真实的教育经历时间"));
                            return;
                        } else {
                            callback();
                            return;
                        }
                    } else {
                        callback(new Error('结束时间不能为空'));
                        return;
                    }
                }

                if(endDateText != '至今') {
                    if (!value) {
                        callback(new Error("结束时间不能为空"));
                    }

                    if (isNaN(new Date(value).getTime())) {
                        callback(new Error('结束时间日期格式不符'));
                    } else if (new Date(value).getTime() < new Date(this.formData.startDate).getTime()) {
                        callback(new Error('结束时间应大于开始时间'));
                    } else if (this.formData.startDate && (new Date(value).getTime() - new Date(this.formData.startDate).getTime() < 365*24*60*60*1000)) {
                        callback(new Error("请输入真实的教育经历时间"));
                    } else {
                        callback();
                    }
                } else {
                    if (moment() - moment(this.formData.startDate) < 365*24*60*60*1000) {
                        callback(new Error("请输入真实的工作经历时间"));
                    } else {
                        callback();
                    }
                }
            },

            validDangeroutStr(rule, value, callback) {
                let dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;
                if(dangerousStringReg.test(value)) {
                    callback(new Error('您输入的内容包含非法字符'));
                } else {
                    callback();
                }
            },

            _submitValidate(){
                let formData = this.formData;

                formData = this.trimValue(formData);

                this.$refs.candidateResumeEducationForm.validate((validator) => {
                    if (validator) {
                        this.loading = true;
                        this._submitCandidateEdit(formData);
                    } else {
                        console.log('error submit')
                    }
                })
            },

            _submitCandidateEdit(formData){
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                let education = {
                    ...formData
                };
                if(endDateText == '至今') {
                    education.endDate = '9999-12-31';
                }
                _request({
                    url: postUrl,
                    method: 'POST',
                    data: {
                        id: this.candidateId,
                        type: this.type,
                        data: {
                            education
                        }    
                    }
                }).then(res =>{
                    this._cancel();
                    this._refresh();
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                }).finally(() =>{
                    this.loading=false;
                })
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                Object.assign(this.formData, data);

                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? new Date(): this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? new Date(): this.formData.startDate;
            },

            initEvent(){
                let self = this;
                setTimeout(() =>{
                    this.getUniversityList();
                    this.getMajorList();
                }, 2000)
            },

            
            getUniversityList(){
                if(!localStorage.getItem('universityList')){
                    _request({
                        url: this.universityUrl,
                        method: 'GET'
                    }).then(res => {
                        let items = res;
                        this.universityList = items;
                        localStorage.setItem('universityList', JSON.stringify(items))
                    })
                }else{
                    let items = JSON.parse(localStorage.getItem('universityList'));
                    setTimeout(() =>{
                        this.universityList = items;
                    })
                }
            },
            getMajorList(){
                if(!localStorage.getItem('majorList')){
                    _request({
                        url: this.majorUrl,
                        method: 'GET'
                    }).then(res => {
                        let items = res;
                            this.majorList = items;
                            localStorage.setItem('majorList', JSON.stringify(items))
                    })
                }else{
                    let items = JSON.parse(localStorage.getItem('majorList'));
                    setTimeout(() =>{
                        this.majorList = items;
                    })
                }
            },
            querySchoolSearch(queryString, cb){
                var schools = this.universityList;
                var results = queryString ? schools.filter(this.createFilter(queryString)) : schools;
                cb(results);
            },
            handleSchool(val){
                this.formData.school = val.name;
            },
            queryMajorSearch(queryString, cb) {
                var majors = this.majorList;
                var results = queryString ? majors.filter(this.createFilter(queryString)) : majors;
                cb(results);
            },
            handleMajor(val){
                this.formData.major = val.name;
            },
            createFilter(queryString) {
                return (item) => {
                    return (item.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
        }
    }
</script>

<style lang="scss">
    /*@import '../../../scss/entry/library.scss';*/
    /*@import '../../../scss/entry/common.scss';*/
    /*@import '../../../scss/entry/myCompany.scss';*/
    @import './candidate-resume-education-form.scss';
    .candidate-resume-education-form{
        .form-group {
            margin-bottom: 0;
        }

        .footer-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pull-right {
                flex-shrink: 0;
            }
        }

        .el-form-item {
            display: flex;
            flex-direction: column;

            .el-form-item__label {
                display: inline-flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                line-height: 24px;

                &::before {
                    margin-left: 4px;
                }
            }

            .el-form-item__content {
                width: 100%;
                margin-left: 0!important;

                .el-select {
                    width: 100%;
                }
            }
        }

        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }

        .el-autocomplete.select-search{
            // width: 320px;
            width: 100%;
            .el-input__inner{
                border: 1px solid #d3d3d3;    
                &:focus {
                    border-color: #d3d3d3;
                }
                &:hover {
                    border-color: #d3d3d3;
                }
            }
        }
    }
    .edit-dialog-autocomplete.el-autocomplete-suggestion{
        margin: 2px 0 0 0;
        .popper__arrow{
            display: none;
        }
        .el-scrollbar__wrap{
            padding: 0;
            max-height: 215px;
            li{
                line-height: 40px;
                height: 40px;
                border-bottom: 1px solid #ececec;
            }
        }
    }
</style>
