var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeProjectForm",
      staticClass: "candidate-resume-project-form col-15",
      attrs: {
        id: "candidate-resume-project-form",
        autocomplete: "off",
        model: _vm.formData,
        rules: _vm.rules,
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目名称", prop: "projectName", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formData.projectName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "projectName", $$v)
                  },
                  expression: "formData.projectName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目角色", prop: "projectRole" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formData.projectRole,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "projectRole", $$v)
                  },
                  expression: "formData.projectRole",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开始时间", prop: "startDate" } },
            [
              _c("el-date-picker", {
                staticClass: "start-date-picker",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "month",
                  placeholder: "如 2018-08",
                  "value-format": "yyyy-MM",
                  "picker-options": _vm.startDateOptions,
                },
                model: {
                  value: _vm.formData.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "startDate", $$v)
                  },
                  expression: "formData.startDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "结束时间", prop: "endDate" } },
            [
              _c("el-date-picker", {
                ref: "endDatePicker",
                staticClass: "end-date-picker",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "month",
                  placeholder: "如 2018-08",
                  "value-format": "yyyy-MM",
                  "picker-options": _vm.endDateOptions,
                },
                model: {
                  value: _vm.formData.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "endDate", $$v)
                  },
                  expression: "formData.endDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-14" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目职责", prop: "responsibility" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入" },
                model: {
                  value: _vm.formData.responsibility,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "responsibility", $$v)
                  },
                  expression: "formData.responsibility",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-14" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目描述", prop: "description" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入" },
                model: {
                  value: _vm.formData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-14 footer-btns" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }, [
          _c("div", { staticClass: "delete-btn", on: { click: _vm._delete } }, [
            _vm._v("删除本条"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }