<template>
    <el-form 
        ref="candidateResumeEvaluationForm" 
        class="candidate-resume-evaluation-form col-15" 
        id="candidate-resume-evaluation-form" 
        autocomplete="off" 
        v-loading="loading"
        :model="formData"
        :rules="rules"
    >

        <div class="form-group col-14">
            <el-form-item label="自我评价" prop="selfEvaluation">
                <el-input type="textarea" :rows="3" v-model="formData.selfEvaluation" />
            </el-form-item>
        </div>

        <div class="form-group col-14">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
        </div>
    </el-form>
</template>

<script>
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { max as getMax} from '#/js/util/validators.js';

    let defaultFormObj = { "selfEvaluation": "" }

    export default {
        data() {
            return {
                formData: Object.assign({}, defaultFormObj),
                rules: {
                    selfEvaluation: [
                        { max: 4000, message: '自我评价最长支持4000个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ],
                },
                type: resumeItemType.selfEvaluation,
                loading:false,
            }
        },
        props:{
            candidateId:String,
            editUrl:String,
        },
        mounted () {
            this.form = this.$refs.candidateResumeEvaluationForm;
            this.initEvent();
        },
        methods: {

            _cancel(){
                this.$emit('resume-evaluation-form-cancel');
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);
            },

            _refresh(){
                this.$emit('candidate-detail-refresh')
            },

            validDangeroutStr(rule, value, callback) {
                let dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;
                if(dangerousStringReg.test(value)) {
                    callback(new Error('您输入的内容包含非法字符'));
                } else {
                    callback();
                }
            },

            _submitValidate(){
                let formData = this.formData;

                formData = this.trimValue(formData);
                
                this.$refs.candidateResumeEvaluationForm.validate((validator) => {
                    if (validator) {
                        this.loading = true;
                        this._submitCandidateEdit(formData);
                    } else {
                        console.log('error submit')
                    }
                })
            },

            _submitCandidateEdit(formData){
                let postUrl = this.editUrl;

                _request({
                    url: postUrl,
                    method: 'POST',
                    data: {
                        id:this.candidateId,
                        type:this.type,
                        data:{
                            selfEvaluation:formData
                        }    
                    }
                }).then(res => {
                    this._cancel();
                    this._refresh();
                    shortTips('编辑成功');
                }).finally(() =>{
                    this.loading=false;
                })
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                // var formInfo = normalizeFormObject(data);
                // mergeObservable(this.formData, data);
                this.formData.selfEvaluation=data;
            },

            initEvent(){
                let self = this;
            }
        }
    }
</script>

<style scoped lang="scss">
    /*@import '../../../scss/entry/library.scss';*/
    /*@import '../../../scss/entry/common.scss';*/
    /*@import '../../../scss/entry/myCompany.scss';*/
    @import './candidate-resume-evaluation-form.scss';
    .candidate-resume-evaluation-form {
        .form-group {
            margin-bottom: 0;
        }

        ::v-deep .el-form-item {
            display: flex;
            flex-direction: column;

            .el-form-item__label {
                display: inline-flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                line-height: 24px;
            }

            .el-form-item__content {
                width: 100%;
                margin-left: 0!important;
            }
        }
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
