var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("div", { staticClass: "candidate-resume-detail row-overflow" }, [
        _vm.isLinkedin
          ? _c("div", { staticClass: "linkedin-tab" }, [
              _c("span", { staticClass: "tab-title" }, [_vm._v("简历详情")]),
              _vm._m(0),
            ])
          : _c(
              "div",
              { staticClass: "resume-tab-type other-resume-tab-type" },
              [
                _c(
                  "span",
                  { staticClass: "type-standard", attrs: { type: "0" } },
                  [_vm._v("标准简历")]
                ),
                _c("span", { staticClass: "slash-line" }, [_vm._v(" / ")]),
                _c(
                  "span",
                  { staticClass: "type-content", attrs: { type: "1" } },
                  [_vm._v("简历原文")]
                ),
              ]
            ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showType == 0,
                expression: "showType == 0",
              },
            ],
            staticClass: "standard-resume",
          },
          [
            _vm.workExperiences.length ||
            _vm.projectExperiences.length ||
            _vm.educationExperiences.length ||
            _vm.educationExperiences.length ||
            _vm.selfEvaluation ||
            _vm.intentions.length
              ? _c("div", { staticClass: "resume-wrapper" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.workExperiences.length,
                          expression: "workExperiences.length",
                        },
                      ],
                      staticClass: "resume-module work-experience",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "module-bar",
                          class: _vm.isFirm ? "has-bar-add-btn" : "",
                        },
                        [
                          _vm._m(1),
                          _vm.canResumeEdit
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.canEdit,
                                      expression: "canEdit",
                                    },
                                  ],
                                  staticClass: "bar-add-btn",
                                  on: { click: _vm.onWorkExperiencesAdd },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _c("span", [_vm._v("增加")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "module-main" },
                        _vm._l(_vm.workExperiences, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "workExperiences" + index,
                              staticClass: "module-item",
                            },
                            [
                              _vm.canResumeEdit
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.canEdit,
                                          expression: "canEdit",
                                        },
                                      ],
                                      staticClass: "bar-add-edit",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onWorkExperiencesEdit(
                                            index,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon-font icon-edit",
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "item-header" }, [
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.startDate !=
                                              "0001-01-01T00:00:00" ||
                                            item.endDate !=
                                              "0001-01-01T00:00:00",
                                          expression:
                                            "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                        },
                                      ],
                                    },
                                    [
                                      _c("i", { staticClass: "header-text" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateWithDotMonth")(
                                              item.startDate
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("i", { staticClass: "header-text" }, [
                                        _vm._v("-"),
                                      ]),
                                      _c("i", { staticClass: "header-text" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateWithDotMonth")(
                                              item.endDate
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("span", {
                                    staticClass: "header-text",
                                    domProps: {
                                      innerHTML: _vm._s(item.company),
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.startDate !=
                                              "0001-01-01T00:00:00" ||
                                            item.endDate !=
                                              "0001-01-01T00:00:00",
                                          expression:
                                            "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                        },
                                      ],
                                      staticClass: "header-text work-time",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("getMonthDiff")(
                                            item.startDate + "|" + item.endDate
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("p", [
                                  _c("span", {
                                    staticClass: "header-text",
                                    domProps: { innerHTML: _vm._s(item.title) },
                                  }),
                                  _c("span", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("getCityTextById")(item.location)
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.description,
                                      expression: "item.description",
                                    },
                                  ],
                                  staticClass: "item-para item-flex",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "para-description" },
                                    [_vm._v("工作描述：")]
                                  ),
                                  _c("span", {
                                    staticClass: "itme-white-space",
                                    domProps: {
                                      innerHTML: _vm._s(item.description),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.projectExperiences.length,
                          expression: "projectExperiences.length",
                        },
                      ],
                      staticClass: "resume-module project-experience",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "module-bar",
                          class: _vm.isFirm ? "has-bar-add-btn" : "",
                        },
                        [
                          _vm._m(2),
                          _vm.canResumeEdit
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.canEdit,
                                      expression: "canEdit",
                                    },
                                  ],
                                  staticClass: "bar-add-btn",
                                  on: { click: _vm.onProjectExperiencesAdd },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _c("span", [_vm._v("增加")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "module-main" },
                        _vm._l(_vm.projectExperiences, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "projectExperiences" + index,
                              staticClass: "module-item",
                            },
                            [
                              _vm.canResumeEdit
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.canEdit,
                                          expression: "canEdit",
                                        },
                                      ],
                                      staticClass: "bar-add-edit",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onProjectExperiencesEdit(
                                            index,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon-font icon-edit",
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "item-header" }, [
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.startDate !=
                                              "0001-01-01T00:00:00" ||
                                            item.endDate !=
                                              "0001-01-01T00:00:00",
                                          expression:
                                            "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                        },
                                      ],
                                    },
                                    [
                                      _c("i", { staticClass: "header-text" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateWithDotMonth")(
                                              item.startDate
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("i", { staticClass: "header-text" }, [
                                        _vm._v("-"),
                                      ]),
                                      _c("i", { staticClass: "header-text" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateWithDotMonth")(
                                              item.endDate
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("span", {
                                    staticClass: "header-text",
                                    domProps: {
                                      innerHTML: _vm._s(item.projectName),
                                    },
                                  }),
                                ]),
                                item.projectRole
                                  ? _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "header-text" },
                                        [
                                          _vm._v(
                                            "项目角色：" +
                                              _vm._s(item.projectRole)
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.description,
                                      expression: "item.description",
                                    },
                                  ],
                                  staticClass: "item-para item-flex",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "para-description" },
                                    [_vm._v("项目描述：")]
                                  ),
                                  _c("span", {
                                    staticClass: "itme-white-space",
                                    domProps: {
                                      innerHTML: _vm._s(item.description),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.responsibility &&
                                        item.responsibility.length > 0,
                                      expression:
                                        "item.responsibility && item.responsibility.length > 0",
                                    },
                                  ],
                                  staticClass: "item-para item-flex",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "para-description" },
                                    [_vm._v("项目职责：")]
                                  ),
                                  _c("span", {
                                    staticClass: "itme-white-space",
                                    domProps: {
                                      innerHTML: _vm._s(item.responsibility),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.educationExperiences.length,
                          expression: "educationExperiences.length",
                        },
                      ],
                      staticClass: "resume-module education-experience",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "module-bar",
                          class: _vm.isFirm ? "has-bar-add-btn" : "",
                        },
                        [
                          _vm._m(3),
                          _vm.canResumeEdit
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.canEdit,
                                      expression: "canEdit",
                                    },
                                  ],
                                  staticClass: "bar-add-btn",
                                  on: { click: _vm.onEducationExperiencesAdd },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _c("span", [_vm._v("增加")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "module-main" },
                        _vm._l(
                          _vm.educationExperiences,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: "educationExperiences" + index,
                                staticClass: "module-item",
                              },
                              [
                                _vm.canResumeEdit
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.canEdit,
                                            expression: "canEdit",
                                          },
                                        ],
                                        staticClass: "bar-add-edit",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onEducationExperiencesEdit(
                                              index,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-font icon-edit",
                                        }),
                                        _c("span", [_vm._v("编辑")]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "item-header" }, [
                                  _c("p", [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.startDate !=
                                                "0001-01-01T00:00:00" ||
                                              item.endDate !=
                                                "0001-01-01T00:00:00",
                                            expression:
                                              "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "header-text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "formatDateWithDotMonth"
                                                )(item.startDate)
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "i",
                                          { staticClass: "header-text" },
                                          [_vm._v("-")]
                                        ),
                                        _c(
                                          "i",
                                          { staticClass: "header-text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "formatDateWithDotMonth"
                                                )(item.endDate)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("span", {
                                      staticClass: "header-text",
                                      domProps: {
                                        innerHTML: _vm._s(item.school),
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.startDate !=
                                                "0001-01-01T00:00:00" ||
                                              item.endDate !=
                                                "0001-01-01T00:00:00",
                                            expression:
                                              "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                          },
                                        ],
                                        staticClass: "header-text work-time",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("getMonthDiff")(
                                              item.startDate +
                                                "|" +
                                                item.endDate
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("span", {
                                      staticClass: "header-text",
                                      domProps: {
                                        innerHTML: _vm._s(item.major),
                                      },
                                    }),
                                    _c("span", { staticClass: "header-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("getDegreeTextById")(
                                            item.degree
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selfEvaluation,
                          expression: "selfEvaluation",
                        },
                      ],
                      staticClass: "resume-module self-evaluation",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "module-bar",
                          class: _vm.isFirm ? "has-bar-add-btn" : "",
                        },
                        [
                          _vm._m(4),
                          _vm.canResumeEdit
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.canEdit,
                                      expression: "canEdit",
                                    },
                                  ],
                                  staticClass: "bar-add-edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEvaluationEdit(
                                        _vm.selfEvaluation,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-edit",
                                  }),
                                  _c("span", [_vm._v("编辑")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "module-main" }, [
                        _c("div", { staticClass: "module-item" }, [
                          _c("div", {
                            staticClass: "item-para itme-white-space",
                            domProps: { innerHTML: _vm._s(_vm.selfEvaluation) },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.intentions.length,
                          expression: "intentions.length",
                        },
                      ],
                      staticClass: "resume-module expect-intentions",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "module-bar",
                          class: _vm.isFirm ? "has-bar-add-btn" : "",
                        },
                        [
                          _vm._m(5),
                          _vm.canResumeEdit
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.canEdit,
                                      expression: "canEdit",
                                    },
                                  ],
                                  staticClass: "bar-add-btn",
                                  on: { click: _vm.onIntentionAdd },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _c("span", [_vm._v("增加")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "module-main" },
                        _vm._l(_vm.intentions, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "intentions" + index,
                              staticClass: "module-item",
                            },
                            [
                              _vm.canResumeEdit
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.canEdit,
                                          expression: "canEdit",
                                        },
                                      ],
                                      staticClass: "bar-add-edit",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onIntentionEdit(
                                            index,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon-font icon-edit",
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "item-header" }, [
                                _c("p", [
                                  _c("span", {
                                    staticClass: "header-text",
                                    domProps: { innerHTML: _vm._s(item.title) },
                                  }),
                                  _c("span", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("getCityTextById")(
                                          item.locationId
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "header-text" }, [
                                    _c("i", [
                                      _vm._v(_vm._s(item.minSalary) + "K"),
                                    ]),
                                    _vm._v(
                                      " -\n                                    "
                                    ),
                                    _c("i", [
                                      _vm._v(_vm._s(item.maxSalary) + "K"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "text-empty" }, [_vm._v("暂无数据")]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showType == 1,
                expression: "showType == 1",
              },
            ],
            staticClass: "original-resume",
          },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.content,
                  expression: "content",
                },
              ],
              staticClass: "resume-wrapper resume-white-space",
              domProps: { innerHTML: _vm._s(_vm.content) },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.content,
                    expression: "!content",
                  },
                ],
                staticClass: "resume-wrapper text-empty resume-white-space",
              },
              [_vm._v("暂无简历原文")]
            ),
          ]
        ),
      ]),
      _c("candidate-resume-work-form", {
        ref: "candidateResumeWorkForm",
        attrs: {
          "candidate-id": _vm.candidateId,
          "edit-url": _vm.editUrl,
          "add-url": _vm.addUrl,
        },
        on: {
          "candidate-detail-refresh": _vm.candidatedetailrefresh,
          "resume-work-form-cancel": _vm.resumeWorkFormCancel,
          "show-delete-dialog": _vm.showDeleteDialog,
          "cache-select-compnay": _vm.cacheSelectCompnay,
        },
      }),
      _c("candidate-resume-education-form", {
        ref: "candidateResumeEducationForm",
        attrs: {
          "candidate-id": _vm.candidateId,
          "edit-url": _vm.editUrl,
          "add-url": _vm.addUrl,
        },
        on: {
          "candidate-detail-refresh": _vm.candidatedetailrefresh,
          "resume-education-form-cancel": _vm.resumeEducationFormCancel,
          "show-delete-dialog": _vm.showDeleteDialog,
        },
      }),
      _c("candidate-resume-project-form", {
        ref: "candidateResumeProjectForm",
        attrs: {
          "candidate-id": _vm.candidateId,
          "edit-url": _vm.editUrl,
          "add-url": _vm.addUrl,
        },
        on: {
          "candidate-detail-refresh": _vm.candidatedetailrefresh,
          "resume-project-form-cancel": _vm.resumeProjectFormCancel,
          "show-delete-dialog": _vm.showDeleteDialog,
        },
      }),
      _c("candidate-resume-evaluation-form", {
        ref: "candidateResumeEvaluationForm",
        attrs: { "candidate-id": _vm.candidateId, "edit-url": _vm.editUrl },
        on: {
          "candidate-detail-refresh": _vm.candidatedetailrefresh,
          "resume-evaluation-form-cancel": _vm.resumeEvaluationFormCancel,
        },
      }),
      _c("candidate-resume-intention-form", {
        ref: "candidateResumeIntentionForm",
        attrs: {
          "candidate-id": _vm.candidateId,
          "edit-url": _vm.editUrl,
          "add-url": _vm.addUrl,
        },
        on: {
          "candidate-detail-refresh": _vm.candidatedetailrefresh,
          "resume-intention-form-cancel": _vm.resumeIntentionFormCancel,
          "show-delete-dialog": _vm.showDeleteDialog,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.deleteLoading,
              expression: "deleteLoading",
            },
          ],
          staticClass: "delete-dialog",
          attrs: {
            visible: _vm.isShowDeleteDialog,
            title: "删除",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowDeleteDialog = $event
            },
          },
        },
        [
          _c("font-icon", {
            staticClass: "info-icon",
            attrs: { href: "#icon-warning-circle-copy" },
          }),
          _vm._v("\n        " + _vm._s(_vm.deleteContent) + "\n        "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.hideDeleteDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleDelete } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "resume-tab-type linkdin-resume-tab-type" },
      [
        _c("span", { staticClass: "type-standard", attrs: { type: "0" } }, [
          _vm._v("标准简历"),
        ]),
        _c("span", { staticClass: "slash-line" }, [_vm._v(" / ")]),
        _c("span", { staticClass: "type-content", attrs: { type: "1" } }, [
          _vm._v("简历原文"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bar-title" }, [
      _c("i", { staticClass: "icon-font icon-briefcase" }),
      _c("span", [_vm._v("工作经历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bar-title" }, [
      _c("i", { staticClass: "icon-font icon-tuceng" }),
      _c("span", [_vm._v("项目经历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bar-title" }, [
      _c("i", { staticClass: "icon-font icon-boshimao" }),
      _c("span", [_vm._v("教育经历")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bar-title" }, [
      _c("i", { staticClass: "icon-font icon-flower" }),
      _c("span", [_vm._v("自我评价")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bar-title" }, [
      _c("i", { staticClass: "icon-font icon-zhiwei1" }),
      _c("span", [_vm._v("期望工作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }