<template>
    <el-form 
        ref="candidateResumeIntentionForm" 
        v-loading="loading" 
        class="candidate-resume-intention-form col-15" 
        id="candidate-resume-intention-form" 
        autocomplete="off"
        :model="formData"
        :rules="rules"
    >
        <input type="hidden" name="Id" :value="formData.id">

        <div class="form-group col-7">
            <el-form-item label="期望职位" prop="title" required>
                <el-input v-model="formData.title" placeholder="请输入" />
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="期望城市" prop="locationId" required>
                <city-select ref="citySelect" @citySelected="handleCitySelected"></city-select>
            </el-form-item>
        </div>

        <div class="form-group col-7">
            <el-form-item label="薪资要求" class="salary-item" required>
                <el-form-item class="min-salary" prop="minSalary">
                    <el-input-number class="min-salary-input" :controls="false" v-model="formData.minSalary" maxlength="3"></el-input-number>
                    <!-- <div v-show="formData.error" class="salary-error"></div> -->
                </el-form-item>
                <span class="salary-tip-text">K</span>
                <span class="divide-line">—</span>
                <el-form-item class="max-salary" prop="maxSalary">
                    <el-input-number class="max-salary-input" :controls="false" v-model="formData.maxSalary" maxlength="3"></el-input-number>
                </el-form-item>
                <span class="salary-tip-text">K</span>
            </el-form-item>
        </div>

        <div class="form-group col-14 footer-btns">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <div class="delete-btn" @click="_delete">删除本条</div>
            </div>
        </div>
    </el-form>
</template>

<script>

    import resumeItemType from '#/js/config/resumeItemType.json';
    import { min as getMin, max as getMax, positiveInteger } from '#/js/util/validators.js';
    import CitySelect from '#/component/common/city-select.vue';


    function processProvinceCityData(list) {
        list.forEach(v => {
            v.value = v.id;
            v.id = v.id;
            v.text = v.text;
            v.selected = false;
            v.disabled = v.disabled||false;
            v.showChildren = false;
            v.children = v.children || [];
            v.visible = true;
            processProvinceCityData(v.children);
        })
    }

    let defaultFormObj = {
        "id": "",
        "title": "",
        "locationId": "",
        "minSalary": "",
        "maxSalary": ""
    }

    export default {
        components: {
            CitySelect
        },
        data() {
            return {
                formData: Object.assign({}, defaultFormObj),
                rules: {
                    title: [
                        { required: true, message: '期望职位不能为空', trigger: ['blur', 'change'] },
                        { max: 128, message: '期望职位最长支持128个字符', trigger: ['blur', 'change'] },
                        { validator: this.validDangeroutStr, trigger: ['blur', 'change'] }
                    ],
                    locationId: [
                        { required: true, message: '期望城市不能为空', trigger: ['blur', 'change'] },
                    ],
                    minSalary: [
                        { validator: this.validMinSalary, trigger: ['blur'] }
                    ],
                    maxSalary: [
                        { validator: this.validMaxSalary, trigger: ['blur'] }
                    ]
                },
                type: resumeItemType.intentions,
                hasSetCityData: false,
                loading:false,
            }
        },
        props:{
            candidateId:String,
            editUrl:String,
            addUrl:String,
        },
        mounted () {
            this.form = this.$refs.candidateResumeIntentionForm;
            this.citySelect = this.$refs.citySelect;
        },
        methods: {

            _cancel(){
                this.$emit('resume-intention-form-cancel');
                this.citySelect.reset();

                this._reset();
            },

            _delete(){
                this._showConfirmDialog();
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);
                this.citySelect.reset();

                this.$nextTick(() => {
                    this.$refs.candidateResumeIntentionForm.clearValidate();
                })
            },

            _refresh(){
                this.$emit('candidate-detail-refresh');
            },

            validMinSalary(rule, value, callback) {
                if(!positiveInteger(value) || !value){
                    callback(new Error('薪资只能为正整数且不能为空'));
                } else {
                    callback();
                }
            },

            validMaxSalary(rule, value, callback) {
                if(!positiveInteger(value) || !value){
                    callback(new Error('薪资只能为正整数且不能为空'));
                } else if(parseInt(value)<parseInt(this.formData.minSalary)){
                    callback(new Error('最大薪资不能小于最小薪资'));
                } else {
                    callback();
                }
            },

            validDangeroutStr(rule, value, callback) {
                let dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;
                if(dangerousStringReg.test(value)) {
                    callback(new Error('您输入的内容包含非法字符'));
                } else {
                    callback();
                }
            },

            _submitValidate(){
                let formData = this.formData;

                formData = this.trimValue(formData);

                this.$refs.candidateResumeIntentionForm.validate((validator) => {
                    if (validator) {
                        this.loading = true;
                        this._submitCandidateEdit(formData);
                    } else {
                        console.log('error submit')
                    }
                })
            },

            _submitCandidateEdit(formData){
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                _request({
                    url: postUrl,
                    method: 'POST',
                    data: {
                        id:this.candidateId,
                        type:this.type,
                        data:{
                            intention:formData
                        } 
                    }
                }).then(res => {
                    this._cancel();
                    this._refresh();
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                }).finally(() =>{
                    this.loading=false;
                })
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                this._reset();
                
                Object.assign(this.formData, data);
                this.citySelect.setDefaultCity(this.formData.locationId);
            },

            handleCitySelected(province, city) {
                this.formData.locationId = city;
            },
        }
    }
</script>

<style lang="scss">
    /*@import '../../../scss/entry/library.scss';*/
    /*@import '../../../scss/entry/common.scss';*/
    /*@import '../../../scss/entry/myCompany.scss';*/
    @import './candidate-resume-intention-form.scss';
    .candidate-resume-detail .standard-resume .candidate-resume-intention-form{
        float: none;

        .form-group {
            margin-bottom: 0;
        }

        .footer-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pull-right {
                flex-shrink: 0;
            }
        }

        .el-form-item {
            display: flex;
            flex-direction: column;

            .el-form-item__label {
                display: inline-flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                line-height: 24px;

                &::before {
                    margin-left: 4px;
                }
            }

            .el-form-item__content {
                width: 100%;
                margin-left: 0!important;

                .el-select {
                    width: 100%;
                }
            }

            &.salary-item {
                .salary-tip-text {
                    font-size: 14px;
                    color: #666;
                    margin-left: 4px;
                }
                .divide-line {
                    margin: 0 4px;
                }
                .el-form-item__content {
                    display: flex;
                    align-items: center;

                    .el-form-item__error {
                        white-space: nowrap;
                    }
                }

                .min-salary, .max-salary {
                    flex: 1;

                    .el-input-number {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
