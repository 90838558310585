var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeWorkForm",
      staticClass: "candidate-resume-work-form col-15",
      attrs: {
        id: "candidate-resume-work-form",
        autocomplete: "off",
        model: _vm.formData,
        rules: _vm.rules,
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c(
        "div",
        {
          staticClass: "form-group col-14 flex-center",
          staticStyle: { "margin-bottom": "15px" },
        },
        [
          _c("el-checkbox", {
            staticClass: "last-experience-checkbox",
            attrs: { disabled: _vm.isCheckboxLocked, size: "medium" },
            model: {
              value: _vm.formData.isLatestWorkExperience,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "isLatestWorkExperience", $$v)
              },
              expression: "formData.isLatestWorkExperience",
            },
          }),
          _c("label", { staticClass: "label-select-text" }, [
            _vm._v("设为最近工作经历"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司", prop: "company", required: "" } },
            [
              _vm.formData.company || _vm.formData.isAdd
                ? _c("customer-select-or-write", {
                    attrs: {
                      customerId: _vm.formData.customerId,
                      customerName: _vm.formData.company,
                    },
                    on: {
                      "update:customerId": function ($event) {
                        return _vm.$set(_vm.formData, "customerId", $event)
                      },
                      "update:customer-id": function ($event) {
                        return _vm.$set(_vm.formData, "customerId", $event)
                      },
                      "update:customerName": function ($event) {
                        return _vm.$set(_vm.formData, "company", $event)
                      },
                      "update:customer-name": function ($event) {
                        return _vm.$set(_vm.formData, "company", $event)
                      },
                      "finish-callback": _vm.finishCallback,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formData.isLatestWorkExperience &&
      _vm.formData.customerId &&
      _vm.showDepart(_vm.formData.tempCustomerId, _vm.formData.customerId)
        ? _c(
            "div",
            { staticClass: "form-group col-7" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "部门", prop: "customerDepartmentId" } },
                [
                  _vm.formData.isLatestWorkExperience && _vm.formData.customerId
                    ? _c("customer-department-select", {
                        ref: "departmentSelect",
                        attrs: {
                          canSrcoll: false,
                          customerId: _vm.formData.customerId,
                          departmentId: _vm.formData.customerDepartmentId,
                          customerDepartmentName:
                            _vm.formData.customerDepartmentName,
                          placeholder: '输入完整路径，多级部门用"，"分隔',
                        },
                        on: { "update-department": _vm.updateDepartment },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "职位", prop: "title", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开始时间", prop: "startDate" } },
            [
              _c("el-date-picker", {
                staticClass: "start-date-picker",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "month",
                  placeholder: "如 2018-08",
                  "value-format": "yyyy-MM",
                  "picker-options": _vm.startDateOptions,
                },
                model: {
                  value: _vm.formData.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "startDate", $$v)
                  },
                  expression: "formData.startDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "结束时间", prop: "endDate" } },
            [
              _c("el-date-picker", {
                ref: "endDatePicker",
                staticClass: "end-date-picker",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "month",
                  placeholder: "如 2018-08",
                  "value-format": "yyyy-MM",
                  "picker-options": _vm.endDateOptions,
                },
                model: {
                  value: _vm.formData.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "endDate", $$v)
                  },
                  expression: "formData.endDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "地区", prop: "locationId" } },
            [
              _c("city-select", {
                ref: "citySelect",
                on: { citySelected: _vm.handleCitySelected },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-14" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "工作内容", prop: "description" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入" },
                model: {
                  value: _vm.formData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-14 footer-btns" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isCheckboxLocked,
                  expression: "!isCheckboxLocked",
                },
              ],
              staticClass: "delete-btn",
              on: { click: _vm._delete },
            },
            [_vm._v("删除本条")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }