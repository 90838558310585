var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeIntentionForm",
      staticClass: "candidate-resume-intention-form col-15",
      attrs: {
        id: "candidate-resume-intention-form",
        autocomplete: "off",
        model: _vm.formData,
        rules: _vm.rules,
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "期望职位", prop: "title", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "期望城市", prop: "locationId", required: "" } },
            [
              _c("city-select", {
                ref: "citySelect",
                on: { citySelected: _vm.handleCitySelected },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "salary-item",
              attrs: { label: "薪资要求", required: "" },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "min-salary", attrs: { prop: "minSalary" } },
                [
                  _c("el-input-number", {
                    staticClass: "min-salary-input",
                    attrs: { controls: false, maxlength: "3" },
                    model: {
                      value: _vm.formData.minSalary,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "minSalary", $$v)
                      },
                      expression: "formData.minSalary",
                    },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "salary-tip-text" }, [_vm._v("K")]),
              _c("span", { staticClass: "divide-line" }, [_vm._v("—")]),
              _c(
                "el-form-item",
                { staticClass: "max-salary", attrs: { prop: "maxSalary" } },
                [
                  _c("el-input-number", {
                    staticClass: "max-salary-input",
                    attrs: { controls: false, maxlength: "3" },
                    model: {
                      value: _vm.formData.maxSalary,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "maxSalary", $$v)
                      },
                      expression: "formData.maxSalary",
                    },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "salary-tip-text" }, [_vm._v("K")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-14 footer-btns" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }, [
          _c("div", { staticClass: "delete-btn", on: { click: _vm._delete } }, [
            _vm._v("删除本条"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }