<template>
    <div v-loading="loading">
        <div class="candidate-resume-detail row-overflow">
            <div v-if="isLinkedin" class="linkedin-tab">
                <span class="tab-title">简历详情</span>
                <div class="resume-tab-type linkdin-resume-tab-type">
                    <span class="type-standard" type="0">标准简历</span>
                    <span class="slash-line"> / </span>
                    <span class="type-content"  type="1">简历原文</span>
                </div>
            </div>
            <!-- <tab ref="tab" border="bottom-none" class="nav-tab"></tab> -->
            <div v-else class="resume-tab-type other-resume-tab-type">
                <span class="type-standard" type="0">标准简历</span>
                <span class="slash-line"> / </span>
                <span class="type-content"  type="1">简历原文</span>
            </div>
            <div class="standard-resume" v-show="showType == 0">
                <div class="resume-wrapper" 
                    v-if="workExperiences.length || projectExperiences.length || educationExperiences.length || educationExperiences.length || selfEvaluation || intentions.length"
                >
                    <div class="resume-module work-experience" v-show="workExperiences.length">
                        <div class="module-bar" :class="isFirm ? 'has-bar-add-btn' : ''">
                            <div class="bar-title">
                                <i class="icon-font icon-briefcase"></i>
                                <span>工作经历</span>
                            </div>
                            <div class="bar-add-btn" @click="onWorkExperiencesAdd" v-show="canEdit" v-if="canResumeEdit">
                                <i class="icon-font icon-add"></i>
                                <span>增加</span>
                            </div>
                        </div>
                        <div class="module-main">
                            <div class="module-item" v-for="(item, index) in workExperiences" :key="'workExperiences'+index">
                                <div class="bar-add-edit" v-show="canEdit" @click="onWorkExperiencesEdit(index, $event)" v-if="canResumeEdit">
                                    <i class="icon-font icon-edit"></i>
                                    <span>编辑</span>
                                </div>
                                <div class="item-header">
                                    <p>
                                        <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                            <i class="header-text">{{ item.startDate | formatDateWithDotMonth }}</i>
                                            <i class="header-text">-</i>
                                            <i class="header-text">{{ item.endDate | formatDateWithDotMonth }}</i>
                                        </span>
                                        <span class="header-text" v-html="item.company"></span>
                                        <span class="header-text work-time" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">{{ item.startDate + '|' + item.endDate | getMonthDiff }}</span>
                                    </p>
                                    <p>
                                        <span class="header-text" v-html="item.title"></span>
                                        <span class="header-text">{{ item.location | getCityTextById }}</span>
                                    </p>
                                </div>
                                <div class="item-para item-flex" v-show="item.description">
                                    <span class="para-description">工作描述：</span>
                                    <span class="itme-white-space" v-html="item.description"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="resume-module project-experience" v-show="projectExperiences.length">
                        <div class="module-bar" :class="isFirm ? 'has-bar-add-btn' : ''">
                            <div class="bar-title">
                                <i class="icon-font icon-tuceng"></i>
                                <span>项目经历</span>
                            </div>
                            <div class="bar-add-btn" v-show="canEdit" @click="onProjectExperiencesAdd" v-if="canResumeEdit">
                                <i class="icon-font icon-add"></i>
                                <span>增加</span>
                            </div>
                        </div>
                        <div class="module-main">
                            <div class="module-item" v-for="(item, index) in projectExperiences" :key="'projectExperiences'+index">
                                <div class="bar-add-edit" v-show="canEdit" @click="onProjectExperiencesEdit(index, $event)" v-if="canResumeEdit">
                                    <i class="icon-font icon-edit"></i>
                                    <span>编辑</span>
                                </div>
                                <div class="item-header">
                                    <p>
                                        <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                            <i class="header-text">{{ item.startDate | formatDateWithDotMonth }}</i>
                                            <i class="header-text">-</i>
                                            <i class="header-text">{{ item.endDate | formatDateWithDotMonth }}</i>
                                        </span>
                                        <span class="header-text" v-html="item.projectName"></span>
                                    </p>
                                    <p v-if="item.projectRole">
                                        <span class="header-text">项目角色：{{item.projectRole}}</span>
                                    </p>
                                </div>
                                <div class="item-para item-flex" v-show="item.description" >
                                    <span class="para-description">项目描述：</span>
                                    <span class="itme-white-space" v-html="item.description"></span>
                                </div>
                                <div class="item-para item-flex" v-show="item.responsibility && item.responsibility.length > 0" >
                                    <span class="para-description">项目职责：</span>
                                    <span class="itme-white-space" v-html="item.responsibility"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="resume-module education-experience" v-show="educationExperiences.length">
                        <div class="module-bar" :class="isFirm ? 'has-bar-add-btn' : ''">
                            <div class="bar-title">
                                <i class="icon-font icon-boshimao"></i>
                                <span>教育经历</span>
                            </div>
                            <div class="bar-add-btn" v-show="canEdit" @click="onEducationExperiencesAdd" v-if="canResumeEdit">
                                <i class="icon-font icon-add"></i>
                                <span>增加</span>
                            </div>
                        </div>
                        <div class="module-main">
                            <div class="module-item" v-for="(item, index) in educationExperiences" :key="'educationExperiences'+index">
                                <div class="bar-add-edit" v-show="canEdit" @click="onEducationExperiencesEdit(index, $event)" v-if="canResumeEdit">
                                    <i class="icon-font icon-edit"></i>
                                    <span>编辑</span>
                                </div>
                                <div class="item-header">
                                    <p>
                                        <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                            <i class="header-text">{{ item.startDate | formatDateWithDotMonth }}</i>
                                            <i class="header-text">-</i>
                                            <i class="header-text">{{ item.endDate | formatDateWithDotMonth }}</i>
                                        </span>
                                        <span class="header-text" v-html="item.school"></span>
                                        <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'" class="header-text work-time">{{ item.startDate + '|' + item.endDate | getMonthDiff }}</span>
                                    </p>
                                    <p>
                                        <span class="header-text" v-html="item.major"></span>
                                        <span class="header-text">{{ item.degree | getDegreeTextById }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="resume-module self-evaluation" v-show="selfEvaluation">
                        <div class="module-bar" :class="isFirm ? 'has-bar-add-btn' : ''">
                            <div class="bar-title">
                                <i class="icon-font icon-flower"></i>
                                <span>自我评价</span>
                            </div>
                            <div class="bar-add-edit" v-show="canEdit" @click="onEvaluationEdit(selfEvaluation, $event)" v-if="canResumeEdit">
                                <i class="icon-font icon-edit"></i>
                                <span>编辑</span>
                            </div>
                        </div>
                        <div class="module-main">
                            <div class="module-item">
                                <div class="item-para itme-white-space" v-html="selfEvaluation"></div>
                            </div>
                        </div>
                    </div>
                    <div class="resume-module expect-intentions" v-show="intentions.length">
                        <div class="module-bar" :class="isFirm ? 'has-bar-add-btn' : ''">
                            <div class="bar-title">
                                <i class="icon-font icon-zhiwei1"></i>
                                <span>期望工作</span>
                            </div>
                            <div class="bar-add-btn" v-show="canEdit" @click="onIntentionAdd" v-if="canResumeEdit">
                                <i class="icon-font icon-add"></i>
                                <span>增加</span>
                            </div>
                        </div>
                        <div class="module-main">
                            <div class="module-item" v-for="(item, index) in intentions" :key="'intentions'+index">
                                <div class="bar-add-edit" v-show="canEdit" @click="onIntentionEdit(index, $event)" v-if="canResumeEdit">
                                    <i class="icon-font icon-edit"></i>
                                    <span>编辑</span>
                                </div>
                                <div class="item-header">
                                    <p>
                                        <span class="header-text" v-html="item.title"></span>
                                        <span class="header-text">{{ item.locationId | getCityTextById }}</span>
                                        <span class="header-text">
                                        <!-- <i>{{ item.minSalary | tpl('%pk') }}</i> -
                                        <i>{{ item.maxSalary | tpl('%pk') }}</i> -->
                                        <i>{{ item.minSalary }}K</i> -
                                        <i>{{ item.maxSalary }}K</i>
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-empty" v-else>暂无数据</div>
            </div>
            <div class="original-resume" v-show="showType == 1">
                <div class="resume-wrapper resume-white-space" v-show="content" v-html="content"></div>
                <div class="resume-wrapper text-empty  resume-white-space" v-show="!content">暂无简历原文</div>
            </div>
        </div>

        <candidate-resume-work-form
            ref="candidateResumeWorkForm"
            @candidate-detail-refresh="candidatedetailrefresh"
            @resume-work-form-cancel="resumeWorkFormCancel"
            @show-delete-dialog="showDeleteDialog"
            :candidate-id="candidateId"
            :edit-url="editUrl"
            :add-url="addUrl"
            @cache-select-compnay="cacheSelectCompnay"
        ></candidate-resume-work-form>
        <candidate-resume-education-form
            ref="candidateResumeEducationForm"
            @candidate-detail-refresh="candidatedetailrefresh"
            @resume-education-form-cancel="resumeEducationFormCancel"
            @show-delete-dialog="showDeleteDialog"
            :candidate-id="candidateId"
            :edit-url="editUrl"
            :add-url="addUrl"
        ></candidate-resume-education-form>
        <candidate-resume-project-form
            ref="candidateResumeProjectForm"
            @candidate-detail-refresh='candidatedetailrefresh'
            @resume-project-form-cancel="resumeProjectFormCancel"
            @show-delete-dialog="showDeleteDialog"
            :candidate-id="candidateId"
            :edit-url="editUrl"
            :add-url="addUrl"
        ></candidate-resume-project-form>
        <candidate-resume-evaluation-form
            ref="candidateResumeEvaluationForm"
            @candidate-detail-refresh='candidatedetailrefresh'
            @resume-evaluation-form-cancel="resumeEvaluationFormCancel"
            :candidate-id="candidateId"
            :edit-url="editUrl"
        ></candidate-resume-evaluation-form>
        <candidate-resume-intention-form
            ref="candidateResumeIntentionForm"
            @candidate-detail-refresh='candidatedetailrefresh'
            @resume-intention-form-cancel="resumeIntentionFormCancel"
            @show-delete-dialog="showDeleteDialog"
            :candidate-id="candidateId"
            :edit-url="editUrl"
            :add-url="addUrl"
        ></candidate-resume-intention-form>
        <el-dialog
            :visible.sync="isShowDeleteDialog"
            title="删除"
            width="500px"
            class="delete-dialog"
            v-loading="deleteLoading"
        >
            <font-icon class="info-icon" href="#icon-warning-circle-copy"></font-icon>
            {{deleteContent}}
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideDeleteDialog">取消</el-button>
                <el-button type="primary" @click="handleDelete">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
   
    import { candidate as candidateConfig } from '#/js/config/api.json';
    import { getCityTextById } from '#/js/util/provinceCity.js';
    
    import CandidateResumeWorkForm from '../component/candidate-resume-work-form/index';
    import CandidateResumeEducationForm from '../component/candidate-resume-education-form/index';
    import CandidateResumeProjectForm from '../component/candidate-resume-project-form/index';
    import CandidateResumeEvaluationForm from '../component/candidate-resume-evaluation-form/index';
    import CandidateResumeIntentionForm from '../component/candidate-resume-intention-form/index';

    const resumeDetailMap = {
        1: "candidateResumeWorkForm",
        2: "candidateResumeEducationForm",
        3: "candidateResumeProjectForm",
        4: "candidateResumeEvaluationForm",
        5: "candidateResumeIntentionForm"
    }
    export default {
        components: {
            // Tab,
            CandidateResumeWorkForm,
            CandidateResumeEducationForm,
            CandidateResumeProjectForm,
            CandidateResumeEvaluationForm,
            CandidateResumeIntentionForm,
        },
        data() {
            return {
                showType: 0,
                originCandidateDetailData: {},
                isFromAuthorization: false,
                workExperiences: [],
                educationExperiences: [],
                projectExperiences: [],
                selfEvaluation: '',
                intentions: [],
                content: '',
                canFileUpload: true,
                originalResume: null,
                resumeOwners: [],
                updated: '',
                popContent:'',
                loading:true,
                isShowDeleteDialog: false,
                deleteLoading: false,
                deleteContent: "",
                deleteFormId: "",
                deleteType: 0,

                editWrokExperencesIndex: -1,
            }
        },
        computed:{
            editUrl() {
                // return placeholder(candidateConfig.url_standard_resume_item_edit,this.candidateId);
                return candidateConfig.url_standard_resume_item_edit.replace(/%p/, this.candidateId);
            },
            addUrl(){
                // return placeholder(candidateConfig.url_standard_resume_item_add,this.candidateId);
                return candidateConfig.url_standard_resume_item_add.replace(/%p/, this.candidateId);
            },
            deleteUrl(){
                // return placeholder(candidateConfig.url_standard_resume_item_delete,this.candidateId)
                return candidateConfig.url_standard_resume_item_delete.replace(/%p/, this.candidateId)
            },
            canResumeEdit() {
                return this.isFirm && !this.isFromAuthorization
            }
        },
        props: {
            candidateId:String,
            isFirm:Boolean,
            resumeId:String,
            canEdit: Boolean,
            isLinkedin: Boolean
        },
        filters: {
            getCityTextById(id) {
                return getCityTextById(id);
            }
        },
        // created(){
        //     eventBus.$on('cache-select-company', this.cacheSelectCompnay)
        // },
        // beforeDestroy() {
        //     eventBus.$off('cache-select-company', this.cacheSelectCompnay)
        // },
        mounted () {
            // // this.tab = this.$refs.tab;
            // this.$component = $('.candidate-resume-detail');

            // this.candidateResumeWorkForm = this.$refs.candidateResumeWorkForm;
            // this.$resumeWorkForm = $('.candidate-resume-work-form');
            // this.$resumeWorkModule = $('.candidate-resume-detail .work-experience');

            // this.candidateResumeEducationForm = this.$refs.candidateResumeEducationForm;
            // this.$resumeEducationForm = $('.candidate-resume-education-form');
            // this.$resumeEducationModule = $('.candidate-resume-detail .education-experience');

            // this.candidateResumeProjectForm = this.$refs.candidateResumeProjectForm;
            // this.$resumeProjectForm = $('.candidate-resume-project-form');
            // this.$resumeProjectModule = $('.candidate-resume-detail .project-experience');

            // this.candidateResumeEvaluationForm = this.$refs.candidateResumeEvaluationForm;
            // this.$resumeEvaluationForm = $('.candidate-resume-evaluation-form');
            // this.$resumeEvaluationModule = $('.candidate-resume-detail .self-evaluation');

            // this.candidateResumeIntentionForm = this.$refs.candidateResumeIntentionForm;
            // this.$resumeIntentionForm = $('.candidate-resume-intention-form');
            // this.$resumeIntentionModule = $('.candidate-resume-detail .expect-intentions');
            this.initEvent();
            this.getUserTypeSelect();

        },
        // updated(){
        //     let navpop = $('.candidate-resume .nav-tab li:nth-child(1)');
        //     if(navpop.length > 0){
        //         this.bindPopOver(navpop,this.popContent);
        //     }
        // },
        updated() {
            this.$component = $('.candidate-resume-detail');

            this.candidateResumeWorkForm = this.$refs.candidateResumeWorkForm;
            this.$resumeWorkForm = $('.candidate-resume-work-form');
            this.$resumeWorkModule = $('.candidate-resume-detail .work-experience');

            this.candidateResumeEducationForm = this.$refs.candidateResumeEducationForm;
            this.$resumeEducationForm = $('.candidate-resume-education-form');
            this.$resumeEducationModule = $('.candidate-resume-detail .education-experience');

            this.candidateResumeProjectForm = this.$refs.candidateResumeProjectForm;
            this.$resumeProjectForm = $('.candidate-resume-project-form');
            this.$resumeProjectModule = $('.candidate-resume-detail .project-experience');

            this.candidateResumeEvaluationForm = this.$refs.candidateResumeEvaluationForm;
            this.$resumeEvaluationForm = $('.candidate-resume-evaluation-form');
            this.$resumeEvaluationModule = $('.candidate-resume-detail .self-evaluation');

            this.candidateResumeIntentionForm = this.$refs.candidateResumeIntentionForm;
            this.$resumeIntentionForm = $('.candidate-resume-intention-form');
            this.$resumeIntentionModule = $('.candidate-resume-detail .expect-intentions');
        },
        methods: {
            // 工作经历选取了公司存入缓存
            cacheSelectCompnay(customerId) {
                this.originCandidateDetailData.workExperiences[this.editWrokExperencesIndex]['tempCustomerId'] = customerId;
            },

            initEvent(){
                let $resumeTypeTab = $('.resume-tab-type');

                $resumeTypeTab.click((ev)=>{
                    let $target = $(ev.target);
                    let $targetType = $target.attr('type');

                    if($targetType!=undefined){
                        this.showType = $targetType;
                        $resumeTypeTab.children().removeClass('active');
                        $target.addClass('active');
                        localStorage.setItem('LBD-CANDIDATEDETAIL-SHOWTYPE',$targetType);
                    }
                })

                // this.subscribe('candidate-detail-refresh',() => {
                //     this.$component.append(this.$resumeWorkForm);
                //     this.$component.append(this.$resumeEducationForm);
                //     this.$component.append(this.$resumeProjectForm);
                //     this.$component.append(this.$resumeEvaluationForm);
                //     this.$component.append(this.$resumeIntentionForm);
                // })
            },

            resumeWorkFormCancel() {
                this.$resumeWorkForm.hide();
                $('.work-experience .module-item').show();
            },

            resumeEducationFormCancel() {
                this.$resumeEducationForm.hide();
                $('.education-experience .module-item').show();
            },

            resumeProjectFormCancel() {
                this.$resumeProjectForm.hide();
                $('.project-experience .module-item').show();
            },

            resumeEvaluationFormCancel() {
                this.$resumeEvaluationForm.hide();
                $('.self-evaluation .module-item').show();
            },

            resumeIntentionFormCancel() {
                this.$resumeIntentionForm.hide();
                $('.expect-intentions .module-item').show();
            },

            bindPopOver(el,content){
                let setting = {
                    placement: "top",
                    trigger: 'hover',
                    delay:500,
                    content: content,
                    template: '<div class="dark-popover popover candidate-resume-detail-popover"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                }

                el.popover(setting);
            },

            getUserTypeSelect(){
                let initResumeType = localStorage.getItem('LBD-CANDIDATEDETAIL-SHOWTYPE') || 0;

                this.showType = initResumeType;

                if(initResumeType == 0){
                    $('.resume-tab-type .type-standard').addClass('active');
                }else{
                    $('.resume-tab-type .type-content').addClass('active');
                }
            },

            //originVm: 未做高亮处理的数据
            update(vm, originVm){
                // let tabSelectionsLen = this.tab.selection.items.length;
                // let selection = null;

                this.originCandidateDetailData = originVm;
                this.originalResume = vm;
                this.workExperiences = vm.workExperiences || [];
                this.educationExperiences = vm.educationExperiences || [];
                this.projectExperiences = vm.projectExperiences || [];
                this.selfEvaluation = vm.selfEvaluation || "";
                this.intentions = vm.intentions || [];
                this.content = vm.content || "";
                this.updated = vm.updated || "";
                this.isFromAuthorization = vm.isFromAuthorization;

                // if(!tabSelectionsLen){
                //     selection = this.addTabSelection(vm.creatorNickname,0);
                //     this.tab.selection.add([selection]);
                //     // LBD-6879
                //     // this.getResumeOwners(vm.talentId,this.$store.state.candidateDetail.candidateInfo.tbdResumeId);
                //     this.popContent = vm.creatorNickname + ' 添加于 ' + vm.created;
                // }

                // if(this.getLettersOfContent(vm.content)>vm.content.length*0.5){
                //     this.tab.selection.select(1);
                // }
                this.loading = false;
            },

            goAnchor(selector){
                let anchor = this.$el.querySelector(selector).scrollIntoView(true);
            },

            onWorkExperiencesEdit(index,ev){
                let $target = $(ev.target);
                let $targetItem = $target.closest('.module-item');

                $targetItem.hide().siblings().show();
                $targetItem.after(this.$resumeWorkForm);
                this.$resumeWorkForm.show();
                
                // 将最近一份工作的部门参数传入
                let customerDepartmentId, customerDepartmentName;
                // 当前工作传入部门名和id
                if(this.originCandidateDetailData.company == this.originCandidateDetailData.workExperiences[index].company) {
                    customerDepartmentId = this.originCandidateDetailData.customerDepartmentId;
                    customerDepartmentName = this.originCandidateDetailData.customerDepartmentName;
                } else {
                    customerDepartmentId = 0;
                    customerDepartmentName = '';
                }

                this.editWrokExperencesIndex = index;
                this.candidateResumeWorkForm.mergeFormData(Object.assign(
                    this.originCandidateDetailData.workExperiences[index], 
                    {
                    customerDepartmentId: customerDepartmentId,
                    customerDepartmentName: customerDepartmentName,
                    customerId: this.originCandidateDetailData.company==this.originCandidateDetailData.workExperiences[index].company ? this.originCandidateDetailData.customerId: null,
                    currentCompany: this.originCandidateDetailData.company,
                    tempCustomerId: this.originCandidateDetailData.workExperiences[index].tempCustomerId || null,
                    }));

                this.goAnchor('#candidate-resume-work-form')
            },

            onWorkExperiencesAdd(){
                this.$resumeWorkModule.append(this.$resumeWorkForm);
                this.$resumeWorkForm.show();
                this.candidateResumeWorkForm._create();
                $('.work-experience .module-item').show();
                this.goAnchor('#candidate-resume-work-form')
            },

            onEducationExperiencesEdit(index,ev){
                let $target = $(ev.target);
                let $targetItem = $target.closest('.module-item');

                $targetItem.hide().siblings().show();
                $targetItem.after(this.$resumeEducationForm);
                this.$resumeEducationForm.show();
                this.candidateResumeEducationForm.mergeFormData(this.originCandidateDetailData.educationExperiences[index]);
                this.goAnchor('#candidate-resume-education-form')
            },

            onEducationExperiencesAdd(){
                this.$resumeEducationModule.append(this.$resumeEducationForm);
                this.candidateResumeEducationForm._reset();
                this.$resumeEducationForm.show();
                $('.education-experience .module-item').show();
                this.goAnchor('#candidate-resume-education-form')
            },

            onProjectExperiencesEdit(index,ev){
                let $target = $(ev.target);
                let $targetItem = $target.closest('.module-item');

                $targetItem.hide().siblings().show();
                $targetItem.after(this.$resumeProjectForm);
                this.$resumeProjectForm.show();
                this.candidateResumeProjectForm.mergeFormData(this.originCandidateDetailData.projectExperiences[index]);
                this.goAnchor('#candidate-resume-project-form')
            },

            onProjectExperiencesAdd(){
                this.$resumeProjectModule.append(this.$resumeProjectForm);
                this.candidateResumeProjectForm._reset();
                this.$resumeProjectForm.show();
                $('.project-experience .module-item').show();
                this.goAnchor('#candidate-resume-project-form')
            },

            onEvaluationEdit(vm,ev){
                let $target = $(ev.target);
                let $targetItem = $target.closest('.self-evaluation').find('.module-item');

                $targetItem.hide();
                $targetItem.after(this.$resumeEvaluationForm);
                this.$resumeEvaluationForm.show();
                this.candidateResumeEvaluationForm.mergeFormData(this.originCandidateDetailData.selfEvaluation);
            },

            onIntentionEdit(index,ev){
                let $target = $(ev.target);
                let $targetItem = $target.closest('.module-item');

                $targetItem.hide().siblings().show();
                $targetItem.after(this.$resumeIntentionForm);
                this.$resumeIntentionForm.show();
                this.candidateResumeIntentionForm.mergeFormData(this.originCandidateDetailData.intentions[index]);
            },

            onIntentionAdd(){
                this.$resumeIntentionModule.append(this.$resumeIntentionForm);
                this.$resumeIntentionForm.show();
                this.candidateResumeIntentionForm._reset();
                $('.expect-intentions .module-item').show();
            },

            getLettersOfContent(content){
                let count = 0;
                for(let i = 0; i<content.length; i++){
                    let charCode = content.charCodeAt(i);
                    if((charCode>=65 && charCode<=90) || (charCode>=97 && charCode<=122)){
                        count++;
                    }
                }
                return count;
            },

            getResumeOwners(talentId,candidateId){
                if(!talentId){
                    console.warn('talentId不存在');
                    return;
                }
                // let url = placeholder(candidateConfig.url_tbd_resume_owners,talentId);
                let url = candidateConfig.url_tbd_resume_owners.replace(/%p/, talentId);
                url += '?resumeId=' + candidateId;

                _request({
                    url: url,
                    method: 'GET'
                }).then((res) => {
                    if(res){
                        this.handleTabSelections(res);
                    }
                })
            },

            handleTabSelections(resumeIds){
                let items = [], selection = null;
                resumeIds.forEach((item,index)=>{
                    selection = this.addTabSelection('全网简历' + (index + 1),item);
                    items.push(selection);
                })
                this.tab.selection.add(items);
            },

            addTabSelection(text,value){
                let selection = {
                    "text": null,
                    "url": null,
                    "icon": null,
                    "iconPopover": null,
                    "iconReverse": null,
                    "iconDescription": null,
                    "type": null,
                    "count": null,
                    "value": null,
                    "title": null,
                    "active": null
                };
                selection.text = text || '简历详情';
                selection.value = value || '';

                return selection;
            },

            candidatedetailrefresh(){
                this.$emit('candidate-detail-refresh')
            },
            showDeleteDialog(type, formId) {
                this.deleteContent = formId ? "确定要删除？删除后不可恢复" : "还没有保存，确定要删除？";
                this.deleteFormId = formId;
                this.deleteType = type;
                this.isShowDeleteDialog = true;
            },
            hideDeleteDialog() {
                this.isShowDeleteDialog = false;
            },
            handleDelete() {
                if(this.deleteFormId) {
                    this.deleteLoading = true;
                    _request({
                        url: this.deleteUrl,
                        method: "POST",
                        header: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            id: this.candidateId,
                            type: this.deleteType,
                            deleteId: this.deleteFormId
                        }
                    }).then((res) => {
                        this.deleteLoading = false;
                        shortTips('删除成功');
                        this.hideDeleteDialog();
                        this.$refs[resumeDetailMap[this.deleteType]]._cancel();
                        this.$refs[resumeDetailMap[this.deleteType]]._refresh();
                    }).catch((err) => {
                        this.deleteLoading = false;
                        this.hideDeleteDialog();
                    });
                } else {
                    this.hideDeleteDialog();
                    this.$refs[resumeDetailMap[this.deleteType]]._cancel();
                }
            }
        },
    }
</script>

<style lang="scss">
    .candidate-resume-detail{
        display: block;
        // border: 1px solid #ddd;
        // border-top: none;
        // background: #fff;
        .nav-tab{
            display: inline-block;
            margin-bottom: -6px;
            width: 80%;
            height: 34px;
            .tab{
                border-bottom:none;
            }
            .selection-item {
                background: #fff;
                font-size: 14px;
                color: #999;
                width: 100px;
                background: #f4f4f4;    
                border: solid 1px #ddd;
                border-top: none;
                border-bottom: none;
                margin-right: 4px;
                &:first-of-type{
                    border-left: none;
                }
                &:before{
                    display: none;
                }
                &.active{
                    background-color: #fff;
                }
                .tab-wrapper{
                    border: none;
                    padding: 6px;
                    background-color: transparent;
                }
            }
        }

        .linkedin-tab {
            padding: 0 40px;
            height: 70px;
            line-height: 70px;
            border-bottom: 1px solid #eee;
            position: relative;

            .tab-title {
                display: inline-block;
                padding: 0 2px;
                height: 100%;
                text-align: center;
                font-size: 16px;
                color: #38BC9D;
                border-bottom: 4px solid #38BC9D;
            }

            .linkdin-resume-tab-type {
                position: absolute;
                right: 20px;
                top: 0;
                span.active{
                    color: #38BC9D;
                }
                .slash-line{
                    margin: 0 5px;
                }
                &:hover{
                    user-select: none;
                    cursor: pointer;
                }
            }
        }

        .side-bar{
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            line-height: 50px;
            width: 600px;
            font-size: 12px;
            border-bottom: 1px solid #ddd;

            .edit-link{
                display: inline-block;
                float: right;
                color: #999;
                &:hover{
                    color: #38bc9c;
                }
            }
        }

        // .resume-tab-type{
        .other-resume-tab-type{
            height: 25px;
            text-align: right;
            font-size: 12px;
            color: #9b9b9b;
            margin-right: 30px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            span.active{
                color: #666;
            }

            .slash-line{
                margin: 0 5px;
            }

            &:hover{
                user-select: none;
                cursor: pointer;
            }
        }
        .resume-update-time{
            p{
                margin: 0;
                background: #fff;
                padding: 20px 0 5px;
                
            }
        }
        .resume-type-standard {
            p {
                background: #fff;
                float: left;
                padding: 0;
                .updated-text{
                    color: #38bc9c;
                }
            }
        }

        .standard-resume{
            padding: 20px 30px;
            .resume-module{
                .module-bar{
                    .bar-title{
                        display: inline-block;
                        margin-bottom: 10px;
                        color: #444;
                        font-weight: bold;
                        .icon-font{
                            color: #666;
                            margin-right: 5px;
                        }
                    }
                    .bar-add-btn{
                        display: none;
                        float: right;
                        color: #38bc9c;

                        .icon-font{
                            font-size: 22px;
                            color: #38bc9c;
                        }

                        &:hover{
                            cursor: pointer;
                        }
                    }

                    &.has-bar-add-btn:hover {
                        .bar-add-btn,.bar-add-edit{
                            display: inline-flex;
                            align-items: center;
                        }
                        &::after{
                            // width: 610px;
                            width: calc(100% - 170px);
                        }
                    }
                    &::after{
                        content: "";   
                        display: inline-block;   
                        // width: 680px;
                        width: calc(100% - 100px);
                        height: 1px;  
                        background-color: rgba(56,188,157,0.2);
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }

                .module-main{
                    position: relative;
                    padding-left: 25px;
                    .module-item{
                        padding-bottom: 16px;
                        &:hover{
                            .bar-add-edit{
                                display: inline-flex;
                                align-items: center;
                            }
                        }
                    }
                    .candidate-resume-work-form,
                    .candidate-resume-education-form,
                    .candidate-resume-project-form,
                    .candidate-resume-evaluation-form,
                    .candidate-resume-intention-form{
                        float: none;
                        margin-left: -20px;
                    }
                }

                .bar-add-edit{
                    display: none;
                    float: right;
                    cursor: pointer;

                    .icon-font{
                        font-size: 20px;
                    }

                    &:hover{
                        cursor: pointer;
                    }
                }

                .item-header{
                    width: 640px;
                    p{
                        line-height: 30px;
                        color: #444;
                        margin-bottom: 0;
                        position: relative;
                        .header-text{
                            display: inline-block;
                            max-width: 300px;
                            vertical-align: middle;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            &.work-time {
                                margin-left: 10px;
                                font-size: 12px;
                                color: #666;
                            }
                        }

                        span + span{
                            margin-left: 20px;
                        }

                        i{
                            font-style: normal;
                        }

                        &:first-child{
                            &:before{
                                content: "";
                                background-color: #CCEEE6;
                                position: absolute;
                                left: -24px;
                                top: 8px;
                                width: 14px;
                                height: 14px;
                                border-radius: 7px;
                                display: inline-block;
                            }
                            &:after{
                                content: "";
                                background-color: $primary;
                                position: absolute;
                                left: -20px;
                                top: 12px;
                                width: 6px;
                                height: 6px;
                                border-radius: 3px;
                                display: inline-block;
                                z-index: 1;
                            }
                        }
                    }
                }

                .item-para{
                    padding: 5px 0;
                    word-wrap: break-word;
                    font-size: 14px;
                    line-height: 20px;
                    &.item-flex{
                        display: flex;
                    }
                    .para-description{
                        white-space: nowrap;
                        display: inline-block;
                        color: #444;
                    }
                }
                .itme-white-space{
                    white-space: pre-line;
                    // width: 660px;
                    width: 90%;
                }

            }
            .candidate-resume-work-form,
            .candidate-resume-education-form,
            .candidate-resume-project-form,
            .candidate-resume-evaluation-form,
            .candidate-resume-intention-form{
                display: block;
            }

            input.hidden{
                display: none;
            }
        }

        .original-resume{
            padding: 20px 30px;
            // background: #fff;
            .resume-wrapper{
                padding-top: 10px;
                word-break: break-word;
            }
            .resume-white-space{
                white-space: pre-line;
            }
        }

        .text-empty{
            text-align: center;
            padding: 20px 0;
        }

        .comment-wrap .title{
            margin-top: 0;
        }
    }

    .modalTemplete {
        overflow-y: auto;
        .modal-header{
            background: #38BC9C;
            color: #fff;

            .close{
                color: #fff;
            }
        }
        .modal-footer button{
            border-radius: 2px;
        }
        .content-side{
            text-align: center;
        }
    }

    .popover.popover-top .popover-arrow::after, .candidate-resume-detail-popover.popover.bs-tether-element-attached-bottom .popover-arrow::after{
        border-top-color: #4d4d4d;
    }
    .candidate-resume-detail .standard-resume .attachment-block{
        .title{
            position: relative;
        }
        .resume-upload{
            display: inline-block;
            float:right;
            .el-upload__input{
                display: none;
            }
            .upload-content{
                font-size: 14px;
                color: #00BD9C;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                .icon-upload{
                    font-size: 14px;
                    color: #00BD9C;
                }
                span{
                    padding-left: 5px;
                }
            }
        }
    }
    .el-dialog__wrapper.delete-dialog {
        .el-dialog__body {
            text-align: center;
            padding: 30px 20px;
        }
        .info-icon {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }
    }
</style>
