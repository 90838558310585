var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeEvaluationForm",
      staticClass: "candidate-resume-evaluation-form col-15",
      attrs: {
        id: "candidate-resume-evaluation-form",
        autocomplete: "off",
        model: _vm.formData,
        rules: _vm.rules,
      },
    },
    [
      _c(
        "div",
        { staticClass: "form-group col-14" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "自我评价", prop: "selfEvaluation" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3 },
                model: {
                  value: _vm.formData.selfEvaluation,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "selfEvaluation", $$v)
                  },
                  expression: "formData.selfEvaluation",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }