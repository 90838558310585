var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeEducationForm",
      staticClass: "candidate-resume-education-form col-15",
      attrs: {
        id: "candidate-resume-education-form",
        autocomplete: "off",
        model: _vm.formData,
        rules: _vm.rules,
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "学校", prop: "school", required: "" } },
            [
              _c("el-autocomplete", {
                staticClass: "select-search select-search-school",
                attrs: {
                  "fetch-suggestions": _vm.querySchoolSearch,
                  placeholder: "请输入内容",
                  "popper-class": "edit-dialog-autocomplete",
                },
                on: { select: _vm.handleSchool },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.formData.school,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "school", $$v)
                  },
                  expression: "formData.school",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "学历", prop: "degree", required: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.degree,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "degree", $$v)
                    },
                    expression: "formData.degree",
                  },
                },
                _vm._l(_vm.degreeArr, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开始时间", prop: "startDate" } },
            [
              _c("el-date-picker", {
                staticClass: "start-date-picker",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "month",
                  placeholder: "如 2018-08",
                  "value-format": "yyyy-MM",
                  "picker-options": _vm.startDateOptions,
                },
                model: {
                  value: _vm.formData.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "startDate", $$v)
                  },
                  expression: "formData.startDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "结束时间", prop: "endDate" } },
            [
              _c("el-date-picker", {
                ref: "endDatePicker",
                staticClass: "end-date-picker",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "month",
                  placeholder: "如 2018-08",
                  "value-format": "yyyy-MM",
                  "picker-options": _vm.endDateOptions,
                },
                model: {
                  value: _vm.formData.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "endDate", $$v)
                  },
                  expression: "formData.endDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group col-7" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "专业", prop: "major" } },
            [
              _c("el-autocomplete", {
                staticClass: "select-search",
                attrs: {
                  "fetch-suggestions": _vm.queryMajorSearch,
                  placeholder: "请输入内容",
                  "popper-class": "edit-dialog-autocomplete",
                },
                on: { select: _vm.handleMajor },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.formData.major,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "major", $$v)
                  },
                  expression: "formData.major",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-14 footer-btns" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }, [
          _c("div", { staticClass: "delete-btn", on: { click: _vm._delete } }, [
            _vm._v("删除本条"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }